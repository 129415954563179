import React from "react"
import styled from "styled-components"

import { Container } from "../components/global"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"

const JobsPage = () => (
  <Layout>
    <SEO title="Jobs" />
    <Navigation />
    <StyledContainer>
      <HeaderWrapper>
        <Subtitle>Cariere</Subtitle>
        Cautam colegi pentru departamentul de Customer Care.
        <br />
        Daca iti doresti sa faci parte din echipa noastra, trimite-ne un mail pe
        adresa office@econta.ro.
      </HeaderWrapper>
    </StyledContainer>
    <Footer />
  </Layout>
)

export default JobsPage

const StyledContainer = styled(Container)``

const HeaderWrapper = styled.header`
  padding: 160px 0 80px 0;
  position: relative;
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`
const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`
